import axios from 'axios'
import { withAuth } from './authService'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + '/paths'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

withAuth(axiosInstance)

export const getSavedPathsByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/save/${userId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching saved paths:', error)
    return []
  }
}

export const savePathForUser = async (path) => {
  try {
    const response = await axiosInstance.post(`/`, path)
    return response.data
  } catch (error) {
    console.error('Error saving path:', error)
  }
}
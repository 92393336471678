import React from 'react'
import { ROLE } from '../../data/SkillSet'

const RoleSelector = ({ selectedRole, onRoleChange }) => {
  const handleRoleChange = (e) => {
    onRoleChange(e.target.value)
  }

  return (
    <div>
      <h3 className="sidebar-title">Career Goal</h3>
      <select
        className="sidebar-select"
        value={selectedRole}
        onChange={handleRoleChange}>
        <option value="UVA CS Path">UVA CS Path</option>
        {Object.entries(ROLE).map(([id, name]) => (
          <option key={id} value={name}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default RoleSelector

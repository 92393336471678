import { auth, googleProvider } from '../firebase/firebase'
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, signInAnonymously, signInWithPopup } from 'firebase/auth'

export const getToken = () => {
  return auth.currentUser ? auth.currentUser.getIdToken() : null
}

export const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    return userCredential.user.getIdToken()
  } catch (error) {
    console.error('Error setting token:', error)
    throw error
  }
}

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider)
    return result.user.getIdToken()
  } catch (error) {
    console.error('Error signing in with Google:', error)
    throw error
  }
}

export const signInAnonymouslyInFirebase = async () => {
  try {
    const result = await signInAnonymously(auth)
    return result.user.getIdToken()
  } catch (error) {
    console.error('Error signing in anonymously:', error)
    throw error
  }
}

export const signOutFromFirebase = async () => {
  try {
    await signOut(auth)
  } catch (error) {
    console.error('Error removing token:', error)
    throw error
  }
}

export const isTokenExpired = async () => {
  if (!auth.currentUser) return true
  const token = await auth.currentUser.getIdTokenResult()
  return token.expirationTime < new Date().toISOString()
}

export const refreshToken = async () => {
  if (!auth.currentUser) throw new Error('No user is signed in')
  return auth.currentUser.getIdToken(true)
}

export const withAuth = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken()
      if (token) {
        config.headers.Authorization = token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}
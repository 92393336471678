import React from 'react'
import ReactDOM from 'react-dom'
import '../../css/component/CourseGraph/CourseCard.css'

const CourseCard = ({ title, content, skills, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'popup-overlay') {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="skills-container">
          <h3>Skills:</h3>
          <ul>
            {skills.map((skill, index) => (
              <li key={index} className="skill-tag">
                {skill}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default CourseCard

import React, { useEffect, useRef } from 'react'
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useReactFlow,
} from 'react-flow-renderer'
import CourseNode from './CourseNode' // 引入自定义节点组件
import VideoNode from './VideoNode' // 新增 VideoNode 导入

// 更新 nodeTypes 定义
const nodeTypes = { courseNode: CourseNode, videoNode: VideoNode }

const CoursePath = ({ courses }) => {
  const reactFlowInstance = useRef(null)
  const { fitView } = useReactFlow()

  // Separate courses and videos
  const coursesOnly = courses.filter((course) => !course.videoId)
  const videos = courses.filter((course) => course.videoId)

  // Calculate course levels and positions
  const courseLevels = {}
  const calculateLevel = (courseId) => {
    if (courseLevels[courseId] !== undefined) return courseLevels[courseId]
    const course = coursesOnly.find((c) => c.id === courseId)
    if (!course) {
      console.warn(`Course with id ${courseId} not found`)
      return 0
    }
    if (!course.prerequisiteId) {
      courseLevels[courseId] = 0
    } else {
      courseLevels[courseId] = calculateLevel(course.prerequisiteId) + 1
    }
    return courseLevels[courseId]
  }
  coursesOnly.forEach((course) => calculateLevel(course.id))

  // Calculate max level
  const maxLevel = Math.max(...Object.values(courseLevels))

  // Group courses by level
  const coursesByLevel = Array.from({ length: maxLevel + 1 }, () => [])
  coursesOnly.forEach((course) => {
    coursesByLevel[courseLevels[course.id]].push(course)
  })

  const nodes = []
  let maxIndex = 0

  // Create course nodes
  coursesByLevel.forEach((levelCourses, level) => {
    levelCourses.forEach((course, index) => {
      maxIndex = Math.max(maxIndex, index)
      nodes.push({
        id: course.id,
        type: 'courseNode',
        data: {
          title: course.title,
          content: course.content,
          link: course.link,
          skills: course.skills,
        },
        position: {
          x: level * 350,
          y: index * 300,
        },
        sourcePosition: 'right',
        targetPosition: 'left',
      })
    })
  })

  // Create video nodes
  videos.forEach((video, index) => {
    const videoLevel = index % (maxLevel + 1)
    const videoRow = Math.floor(index / (maxLevel + 1))
    nodes.push({
      id: video.id,
      type: 'videoNode',
      data: {
        title: video.title,
        content: video.content,
        videoId: video.videoId,
        skills: video.skills,
      },
      position: {
        x: videoLevel * 350,
        y: (maxIndex + 1 + videoRow) * 300,
      },
      sourcePosition: 'right',
      targetPosition: 'left',
    })
  })

  // Update edges creation to use coursesOnly
  const edges = coursesOnly
    .filter(
      (course) =>
        course.prerequisiteId &&
        coursesOnly.some((c) => c.id === course.prerequisiteId)
    )
    .map((course) => ({
      id: `e${course.prerequisiteId}-${course.id}`,
      source: course.prerequisiteId,
      target: course.id,
      type: 'bezier',
    }))

  useEffect(() => {
    if (reactFlowInstance.current) {
      setTimeout(() => {
        fitView({ padding: 0.2, duration: 800 })
      }, 50)
    }
  }, [courses, fitView])

  return (
    <div style={{ height: '800px', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onInit={(instance) => {
          reactFlowInstance.current = instance
          fitView({ padding: 0.2 })
        }}
        minZoom={0.1}
        maxZoom={1.5}>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  )
}

export default CoursePath

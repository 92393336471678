import React, { useState } from 'react'
import { Handle } from 'react-flow-renderer'
import CourseCard from './CourseCard'
import '../../css/component/CourseGraph/node.css'

// 自定义节点组件，显示课程信息，并且支持连线
const CourseNode = ({ data }) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleLearnMoreClick = (e) => {
    e.preventDefault()
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
  }

  return (
    <div className="node course-node">
      <h3 className="node-title">{data.title}</h3>
      <p className="course-node-content">{data.content}</p>
      <button className="course-node-button" onClick={handleLearnMoreClick}>
        Learn more
      </button>
      {/* 添加 source 和 target 连接点 */}
      <Handle type="source" position="right" />
      <Handle type="target" position="left" />

      {showPopup && (
        <CourseCard
          title={data.title}
          content={data.content}
          onClose={handleClosePopup}
          skills={data.skills}
        />
      )}
    </div>
  )
}

export default CourseNode

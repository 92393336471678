import axios from 'axios'
import { withAuth } from './authService'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

withAuth(axiosInstance)

export const getVideoBySkill = async (skill) => {
  try {
    const response = await axiosInstance.get(`/videos/${skill}`)
    const video = response.data
    console.log('Success fetching videos for skill', skill)
    return video
  } catch (error) {
    throw error
  }
}
export const UVACourseData = [
  {
    id: '1010',
    title: 'CS 1010: Introduction to Information Technology',
    content:
      'How computers create, preserve, manipulate and communicate information and the concepts and tools used to that end.',
    link: 'https://example.com/course/1010',
    prerequisiteId: null,
    skills: ['Information Systems', 'Data Management', 'Computer Basics'],
  },
  {
    id: '1110',
    title: 'CS 1110: Introduction to Programming',
    content:
      'A first course in programming, software development, and computer science. Introduces computing fundamentals and an appreciation for computational thinking.',
    link: 'https://example.com/course/1110',
    prerequisiteId: null,
    skills: ['Programming', 'Software Development', 'Computational Thinking'],
  },
  {
    id: '2100',
    title: 'CS 2100: Data Structures and Algorithms 1',
    content:
      'A second course in computing with an emphasis on foundational data structures and program analysis.',
    link: 'https://example.com/course/2100',
    prerequisiteId: '1110',
    skills: ['Data Structures', 'Algorithms', 'Program Analysis'],
  },
  {
    id: '2120',
    title: 'CS 2120: Discrete Mathematics and Theory 1',
    content:
      'Introduces discrete mathematics and proof techniques involving first order predicate logic and induction.',
    link: 'https://example.com/course/2120',
    prerequisiteId: '1110',
    skills: ['Discrete Mathematics', 'Logic', 'Proof Techniques'],
  },
  {
    id: '2130',
    title: 'CS 2130: Computer Systems and Organization 1',
    content:
      'Covers topics on the computer architecture abstraction hierarchy ranging from a step above silicon to a step below modern programming languages.',
    link: 'https://example.com/course/2130',
    prerequisiteId: '1110',
    skills: [
      'Computer Architecture',
      'Low-Level Programming',
      'Systems Organization',
    ],
  },
  {
    id: '3100',
    title: 'CS 3100: Data Structures and Algorithms 2',
    content:
      'Builds upon previous analysis of algorithms and the effects of data structures on them.',
    link: 'https://example.com/course/3100',
    prerequisiteId: '2100',
    skills: [
      'Advanced Data Structures',
      'Algorithm Optimization',
      'Program Analysis',
    ],
  },
  {
    id: '3120',
    title: 'CS 3120: Discrete Mathematics and Theory 2',
    content:
      'Understand the fundamental limits on what can be efficiently computed.',
    link: 'https://example.com/course/3120',
    prerequisiteId: '2120',
    skills: [
      'Computational Complexity',
      'Mathematical Logic',
      'Proof Techniques',
    ],
  },
  {
    id: '3130',
    title: 'CS 3130: Computer Systems and Organization 2',
    content:
      'A second course in computer systems, exploring a more realistic model of processors and how they and the operating system work together.',
    link: 'https://example.com/course/3130',
    prerequisiteId: '2130',
    skills: [
      'Operating Systems',
      'Processor Architecture',
      'System-Level Programming',
    ],
  },
  {
    id: '3140',
    title: 'CS 3140: Software Development Essentials',
    content:
      'A first course in software engineering and software construction, focusing on building more complex systems.',
    link: 'https://example.com/course/3140',
    prerequisiteId: '2100',
    skills: ['Software Engineering', 'System Design', 'Software Construction'],
  },
  {
    id: '3240',
    title: 'CS 3240: Software Engineering',
    content:
      'Analyzes modern software engineering practice for multi-person projects.',
    link: 'https://example.com/course/3240',
    prerequisiteId: '3140',
    skills: [
      'Team Collaboration',
      'Software Project Management',
      'Software Development',
    ],
  },
  {
    id: '3710',
    title: 'CS 3710: Introduction to Cybersecurity',
    content:
      'Introduces students to the fields of cybersecurity, covering both non-technical and technical issues.',
    link: 'https://example.com/course/3710',
    prerequisiteId: '2130',
    skills: ['Cybersecurity', 'Threat Analysis', 'Network Security'],
  },
  {
    id: '4414',
    title: 'CS 4414: Operating Systems',
    content:
      'Analyzes process communication and synchronization, resource management, virtual memory management algorithms, file systems, and networking and distributed systems.',
    link: 'https://example.com/course/4414',
    prerequisiteId: '3130',
    skills: [
      'Operating Systems',
      'Process Synchronization',
      'Memory Management',
      'File Systems',
    ],
  },
  {
    id: '4610',
    title: 'CS 4610: Programming Languages',
    content:
      'Presents the fundamental concepts of programming language design and implementation.',
    link: 'https://example.com/course/4610',
    prerequisiteId: '3140',
    skills: [
      'Programming Language Design',
      'Language Implementation',
      'Compiler Design',
    ],
  },
  {
    id: '4710',
    title: 'CS 4710: Artificial Intelligence',
    content:
      'Introduces artificial intelligence, covering fundamental concepts and techniques and surveying selected application areas.',
    link: 'https://example.com/course/4710',
    prerequisiteId: '3100',
    skills: ['Artificial Intelligence', 'Machine Learning', 'Algorithm Design'],
  },
  {
    id: '4750',
    title: 'CS 4750: Database Systems',
    content:
      'Introduces the fundamental concepts for design and development of database systems.',
    link: 'https://example.com/course/4750',
    prerequisiteId: '3140',
    skills: ['Database Design', 'SQL', 'Data Management'],
  },
  {
    id: '4780',
    title: 'CS 4780: Information Retrieval',
    content:
      'An introduction to modern information retrieval technologies. Topics include indexing, query processing, document ranking, query recommendation, personalization, and other current topics in information retrieval.',
    link: 'https://example.com/course/4780',
    prerequisiteId: '3100',
    skills: ['Information Retrieval', 'Search Algorithms', 'Document Ranking'],
  },
  {
    id: '4810',
    title: 'CS 4810: Introduction to Computer Graphics',
    content:
      'Introduces the fundamentals of three-dimensional computer graphics: rendering, modeling, and animation.',
    link: 'https://example.com/course/4810',
    prerequisiteId: '3100',
    skills: ['3D Graphics', 'Rendering', 'Animation'],
  },
  {
    id: '4970',
    title: 'CS 4970: Capstone Practicum I',
    content:
      'This course is one option in the CS fourth-year thesis track. It forms the first part of a year-long group-based and project-based practicum class.',
    link: 'https://example.com/course/4970',
    prerequisiteId: '3140',
    skills: [
      'Capstone Project',
      'Team Collaboration',
      'Project-Based Learning',
    ],
  },
  {
    id: '4971',
    title: 'CS 4971: Capstone Practicum II',
    content:
      'This course is the continuation from CS 4970, forming the second part of a year-long group-based and project-based practicum class.',
    link: 'https://example.com/course/4971',
    prerequisiteId: '4970',
    skills: [
      'Capstone Project',
      'Advanced Project Management',
      'Team-Based Development',
    ],
  },
  {
    id: '5010',
    title: 'CS 5010: Programming and Systems for Data Science',
    content:
      'Introduces basic data analysis techniques including data analysis at scale, in the context of real-world domains. Students will be introduced to essential programming techniques in Python.',
    link: 'https://example.com/course/5010',
    prerequisiteId: '1110',
    skills: ['Data Analysis', 'Python Programming', 'Data Science'],
  },
  {
    id: '6160',
    title: 'CS 6160: Theory of Computation',
    content:
      "Analyzes formal languages, the Chomsky hierarchy, formal computation and machine models, finite automata, pushdown automata, Turing machines, Church's thesis, reductions, decidability and undecidability, and NP-completeness.",
    link: 'https://example.com/course/6160',
    prerequisiteId: '3120',
    skills: ['Formal Languages', 'Automata Theory', 'NP-Completeness'],
  },
  {
    id: '6161',
    title: 'CS 6161: Design and Analysis of Algorithms',
    content:
      'Analyzes concepts in algorithm design, problem solving strategies, proof techniques, complexity analysis, upper and lower bounds, sorting and searching, graph algorithms, geometric algorithms, probabilistic algorithms, intractability and NP-completeness, transformations, and approximation algorithms.',
    link: 'https://example.com/course/6161',
    prerequisiteId: '3100',
    skills: ['Algorithm Design', 'Complexity Analysis', 'Graph Algorithms'],
  },
  {
    id: '6316',
    title: 'CS 6316: Machine Learning',
    content:
      'This is a graduate-level machine learning course. Covers introductory topics about the theory and practical algorithms for machine learning from a variety of perspectives.',
    link: 'https://example.com/course/6316',
    prerequisiteId: '4710',
    skills: ['Machine Learning', 'Algorithm Development', 'Data Analysis'],
  },
  {
    id: '6750',
    title: 'CS 6750: Database Systems',
    content:
      'Studies new database systems, emphasizing database design and related system issues. Explores advanced topics such as object-oriented and real-time database systems, data warehousing, data mining, and workflow.',
    link: 'https://example.com/course/6750',
    prerequisiteId: '4750',
    skills: ['Database Systems', 'Data Mining', 'Object-Oriented Databases'],
  },
  {
    id: '3205',
    title: 'CS 3205: HCI in Software Development',
    content:
      'Human-computer interaction and user-centered design in the context of software engineering. Examines the fundamental principles of human-computer interaction.',
    link: 'https://example.com/course/3205',
    prerequisiteId: '2100',
    skills: [
      'Human-Computer Interaction',
      'User-Centered Design',
      'Software Engineering',
    ],
  },
  {
    id: '3250',
    title: 'CS 3250: Software Testing',
    content:
      'An introduction to testing for assuring software quality. Covers concepts and techniques for testing software, including testing at the unit, module, subsystem, and system levels.',
    link: 'https://example.com/course/3250',
    prerequisiteId: '2100',
    skills: ['Software Testing', 'Quality Assurance', 'Test Automation'],
  },
  {
    id: '4260',
    title: 'CS 4260: Internet Scale Applications',
    content:
      'A survey of methods for building large-scale internet websites and mobile apps, with a focus on how theory meets practice.',
    link: 'https://example.com/course/4260',
    prerequisiteId: '3240',
    skills: ['Web Development', 'Mobile App Development', 'Scalability'],
  },
  {
    id: '4434',
    title: 'CS 4434: Dependable Computing Systems',
    content:
      'Focuses on techniques for designing & analyzing dependable computer-based systems.',
    link: 'https://example.com/course/4434',
    prerequisiteId: '3130',
    skills: [
      'Dependable Systems',
      'Fault-Tolerant Systems',
      'System Reliability',
    ],
  },
  {
    id: '4444',
    title: 'CS 4444: Introduction to Parallel Computing',
    content:
      'Introduces the basics of high-performance parallel computing and the national cyber-infrastructure.',
    link: 'https://example.com/course/4444',
    prerequisiteId: '3130',
    skills: [
      'Parallel Computing',
      'High-Performance Computing',
      'Cyber-Infrastructure',
    ],
  },
  {
    id: '4457',
    title: 'CS 4457: Computer Networks',
    content:
      'A first course in communication networks for upper-level undergraduate students.',
    link: 'https://example.com/course/4457',
    prerequisiteId: '3130',
    skills: ['Networking', 'TCP/IP', 'Communication Protocols'],
  },
  {
    id: '4501',
    title: 'CS 4501: Special Topics in Computer Science',
    content:
      'Content varies annually, depending on instructor interests and the needs of the department.',
    link: 'https://example.com/course/4501',
    prerequisiteId: '2150',
    skills: ['Research Skills', 'Critical Thinking', 'Specialized Knowledge'],
  },
  {
    id: '4630',
    title: 'CS 4630: Defense Against the Dark Arts',
    content:
      'Viruses, worms, and other malicious software are an ever-increasing threat to computer systems.',
    link: 'https://example.com/course/4630',
    prerequisiteId: '3710',
    skills: ['Malware Analysis', 'Cybersecurity', 'Threat Detection'],
  },
  {
    id: '4720',
    title: 'CS 4720: Mobile Application Development',
    content:
      'Focuses on the creation of mobile solutions for various modern platforms, including major mobile operating systems.',
    link: 'https://example.com/course/4720',
    prerequisiteId: '3140',
    skills: [
      'Mobile App Development',
      'iOS Development',
      'Android Development',
    ],
  },
  {
    id: '4740',
    title: 'CS 4740: Cloud Computing',
    content:
      'Investigates the architectural foundations of the various cloud platforms, as well as examining both current cloud computing platforms and modern cloud research.',
    link: 'https://example.com/course/4740',
    prerequisiteId: '3140',
    skills: [
      'Cloud Computing',
      'Distributed Systems',
      'Infrastructure Management',
    ],
  },
  {
    id: '4774',
    title: 'CS 4774: Machine Learning',
    content:
      'An introduction to machine learning: the study of algorithms that improve their performance through experience.',
    link: 'https://example.com/course/4774',
    prerequisiteId: '3100',
    skills: ['Machine Learning', 'Data Analysis', 'Algorithm Development'],
  },
  {
    id: '6111',
    title: 'CS 6111: Cloud Computing',
    content:
      'This course introduces a basic grounding in designing and implementing cloud systems.',
    link: 'https://example.com/course/6111',
    prerequisiteId: '4457',
    skills: ['Cloud Systems', 'Infrastructure Design', 'Cloud Architecture'],
  },
  {
    id: '6333',
    title: 'CS 6333: Mobile and IoT Security',
    content:
      'This course focuses on aspects of system security that arise in this challenging and ever-evolving space of mobile communication systems, primarily focusing on smartphones and IoT platforms.',
    link: 'https://example.com/course/6333',
    prerequisiteId: '3710',
    skills: ['Mobile Security', 'IoT Security', 'Cybersecurity'],
  },
  {
    id: '6465',
    title: 'CS 6465: Human-Robot Interaction',
    content:
      'Interactions between robots and humans are influenced by form, function and expectations.',
    link: 'https://example.com/course/6465',
    prerequisiteId: '4710',
    skills: ['Human-Robot Interaction', 'Robotics', 'User Interaction'],
  },
  {
    id: '1111',
    title: 'CS 1111: Introduction to Programming',
    content:
      'A first course in programming for students with some programming experience. Introduces computing fundamentals and an appreciation for computational thinking.',
    link: 'https://example.com/course/1111',
    prerequisiteId: null,
    skills: ['Programming', 'Software Development', 'Computational Thinking'],
  },
  {
    id: '1112',
    title: 'CS 1112: Introduction to Programming',
    content:
      'A first course in programming for students with no previous programming experience. Introduces computing fundamentals and an appreciation for computational thinking.',
    link: 'https://example.com/course/1112',
    prerequisiteId: null,
    skills: ['Programming', 'Software Development', 'Computational Thinking'],
  },
  {
    id: '1113',
    title: 'CS 1113: Introduction to Programming',
    content:
      'A first course in programming with special domain topics. Introduces computing fundamentals and an appreciation for computational thinking.',
    link: 'https://example.com/course/1113',
    prerequisiteId: null,
    skills: ['Programming', 'Software Development', 'Computational Thinking'],
  },
  {
    id: '1120',
    title:
      'CS 1120: Introduction to Computing: Explorations in Language, Logic, and Machines',
    content:
      'This course is an introduction to the most important ideas in computing, focusing on big ideas including recursive definitions, universality, and abstraction.',
    link: 'https://example.com/course/1120',
    prerequisiteId: null,
    skills: ['Language Theory', 'Logic', 'Computational Abstraction'],
  },
  {
    id: '2910',
    title: 'CS 2910: CS Education Practicum',
    content:
      'An overview of computer science education for undergraduate students. Topics include ethics, diversity, tutoring and teaching techniques, and classroom management.',
    link: 'https://example.com/course/2910',
    prerequisiteId: '1110',
    skills: ['Teaching Techniques', 'Classroom Management', 'Ethics'],
  },
  {
    id: '4998',
    title: 'CS 4998: Distinguished BA Majors Research',
    content:
      'Required for Distinguished Majors completing the Bachelor of Arts degree in the College of Arts and Sciences. An introduction to computer science research and the writing of a Distinguished Majors thesis.',
    link: 'https://example.com/course/4998',
    prerequisiteId: '3100',
    skills: ['Research Skills', 'Thesis Writing', 'Academic Research'],
  },
  {
    id: '5014',
    title: 'CS 5014: Computation as a Research Tool',
    content:
      'This course is an introduction to programming for students who will be using computational methods for their research but are not computer science or computer engineering students.',
    link: 'https://example.com/course/5014',
    prerequisiteId: null,
    skills: ['Research Programming', 'Computation', 'Data Analysis'],
  },
  {
    id: '6190',
    title: 'CS 6190: Computer Science Perspectives',
    content:
      "This 'acclimation' seminar helps new graduate students become productive researchers. Faculty and visitors speak on a wide variety of research topics.",
    link: 'https://example.com/course/6190',
    prerequisiteId: null,
    skills: ['Research Techniques', 'Graduate Research', 'Topic Exploration'],
  },
  {
    id: '6222',
    title: 'CS 6222: Introduction to Cryptography',
    content:
      'This course will provide an introduction to modern cryptography and its applications to computer security.',
    link: 'https://example.com/course/6222',
    prerequisiteId: '3120',
    skills: ['Cryptography', 'Computer Security', 'Encryption Techniques'],
  },
  {
    id: '6354',
    title: 'CS 6354: Computer Architecture',
    content:
      'Study of representative digital computer organization with emphasis on control unit logic, input/output processors and devices, asynchronous processing, concurrency, and parallelism.',
    link: 'https://example.com/course/6354',
    prerequisiteId: '3130',
    skills: ['Computer Architecture', 'Asynchronous Processing', 'Parallelism'],
  },
  {
    id: '6456',
    title: 'CS 6456: Operating Systems',
    content:
      'Covers advanced principles of operating systems. Technical topics include support for distributed OSs, microkernels and OS architectures, processes and threads, IPC, files servers, and more.',
    link: 'https://example.com/course/6456',
    prerequisiteId: '4414',
    skills: [
      'Advanced Operating Systems',
      'Microkernels',
      'Process Communication',
    ],
  },
  {
    id: '6620',
    title: 'CS 6620: Compilers',
    content:
      'Study of the theory, design, and specification of translation systems. Translation systems are the tools used to translate a source language program to a form that can be executed.',
    link: 'https://example.com/course/6620',
    prerequisiteId: '4610',
    skills: ['Compiler Design', 'Language Translation', 'Code Generation'],
  },
  {
    id: '6666',
    title: 'CS 6666: Data Mining - Principles and Algorithms',
    content:
      'This graduate-level course introduces the fundamental techniques, algorithms, and applications of data mining.',
    link: 'https://example.com/course/6666',
    prerequisiteId: '4774',
    skills: ['Data Mining', 'Algorithm Development', 'Data Analysis'],
  },
]

export const VideoData = [
  {
    id: 'zJSY8tbf_ys',
    videoId: 'zJSY8tbf_ys',
    title: 'Frontend Web Development Bootcamp Course (JavaScript, HTML, CSS)',
    content:
      'This comprehensive frontend web development course covers essential skills for aspiring front-end developers. It includes lessons on JavaScript, HTML, and CSS, which are core technologies for front-end development.',
    link: 'https://www.youtube.com/watch?v=zJSY8tbf_ys',
    prerequisiteId: null,
    skills: [
      'HTML',
      'CSS',
      'JavaScript',
      'Responsive Design',
      'Web Accessibility',
    ],
  },
  {
    id: 'rfscVS0vtbw',
    videoId: 'rfscVS0vtbw',
    title: 'Learn Python - Full Course for Beginners',
    content:
      "This Python course covers all the basics of Python programming, including data types, control structures, functions, and more. It's perfect for beginners in software engineering or data science.",
    link: 'https://www.youtube.com/watch?v=rfscVS0vtbw',
    prerequisiteId: null,
    skills: ['Python', 'Software Engineer', 'Data Scientist'],
  },
  {
    id: 'Oe421EPjeBE',
    videoId: 'Oe421EPjeBE',
    title: 'Node.js and Express.js - Full Course',
    content:
      'This course covers Node.js and Express.js, essential technologies for back-end development. Learn how to build RESTful APIs and handle server-side logic.',
    link: 'https://www.youtube.com/watch?v=Oe421EPjeBE',
    prerequisiteId: null,
    skills: ['Node.js', 'RESTful APIs', 'Back-end Developer'],
  },
  {
    id: 'pTFZFxd4hOI',
    videoId: 'pTFZFxd4hOI',
    title: 'Docker Tutorial for Beginners',
    content:
      'Learn Docker from scratch in this comprehensive tutorial. Understand containerization and how to use Docker in your development workflow.',
    link: 'https://www.youtube.com/watch?v=pTFZFxd4hOI',
    prerequisiteId: null,
    skills: ['Docker', 'DevOps Engineer', 'Full-stack Developer'],
  },
  {
    id: 'HXV3zeQKqGY',
    videoId: 'HXV3zeQKqGY',
    title: 'SQL - Full Course for Beginners',
    content:
      'This course covers everything you need to know about SQL, from basic queries to advanced database management techniques.',
    link: 'https://www.youtube.com/watch?v=HXV3zeQKqGY',
    prerequisiteId: null,
    skills: [
      'SQL',
      'Database Management',
      'Back-end Developer',
      'Data Scientist',
    ],
  },
  {
    id: 'RGOj5yH7evk',
    videoId: 'RGOj5yH7evk',
    title: 'Git and GitHub for Beginners - Crash Course',
    content:
      'Learn the basics of Git and GitHub, essential tools for version control and collaboration in software development.',
    link: 'https://www.youtube.com/watch?v=RGOj5yH7evk',
    prerequisiteId: null,
    skills: ['Git', 'Software Engineer', 'DevOps Engineer'],
  },
  {
    id: 'Wy9q22isx3U',
    videoId: 'Wy9q22isx3U',
    title: 'React JS - React Tutorial for Beginners',
    content:
      'This React tutorial covers the fundamentals of React, including components, state, and props. Perfect for aspiring front-end developers.',
    link: 'https://www.youtube.com/watch?v=Wy9q22isx3U',
    prerequisiteId: null,
    skills: ['React', 'JavaScript', 'Front-end Developer'],
  },
  {
    id: 'fis26HvvDII',
    videoId: 'fis26HvvDII',
    title: 'Android Development for Beginners - Full Course',
    content:
      'Learn Android app development from scratch. This course covers Java, Android SDK, and mobile UI/UX design principles.',
    link: 'https://www.youtube.com/watch?v=fis26HvvDII',
    prerequisiteId: null,
    skills: ['Java', 'Android SDK', 'Mobile App Developer'],
  },
  {
    id: 'r1xBCi5SOjw',
    videoId: 'r1xBCi5SOjw',
    title: 'AWS Certified Cloud Practitioner Training 2020 - Full Course',
    content:
      'Prepare for the AWS Certified Cloud Practitioner exam with this comprehensive course covering AWS services and cloud concepts.',
    link: 'https://www.youtube.com/watch?v=r1xBCi5SOjw',
    prerequisiteId: null,
    skills: ['AWS', 'Cloud Architect', 'DevOps Engineer'],
  },
  {
    id: 'fNk_zzaMoSs',
    videoId: 'fNk_zzaMoSs',
    title: 'Learn Tensor Flow and Deep Learning fundamentals with Python',
    content:
      'This course covers the basics of TensorFlow and deep learning, essential skills for machine learning engineers and data scientists.',
    link: 'https://www.youtube.com/watch?v=fNk_zzaMoSs',
    prerequisiteId: null,
    skills: ['TensorFlow', 'Deep Learning', 'Machine Learning Engineer'],
  },
  {
    id: 'qw--VYLpxG4',
    videoId: 'qw--VYLpxG4',
    title: 'Learn C++ Programming - Beginner to Advanced',
    content:
      'This comprehensive C++ course covers everything from basic syntax to advanced concepts like object-oriented programming.',
    link: 'https://www.youtube.com/watch?v=qw--VYLpxG4',
    prerequisiteId: null,
    skills: ['C++', 'Object-Oriented Programming', 'Software Engineer'],
  },
  {
    id: 'ua-CiDNNj30',
    videoId: 'ua-CiDNNj30',
    title: 'Microservices Architectural Design Patterns Playbook',
    content:
      'Learn about microservices architecture and design patterns, crucial for modern back-end and full-stack development.',
    link: 'https://www.youtube.com/watch?v=ua-CiDNNj30',
    prerequisiteId: null,
    skills: ['Microservices', 'Back-end Developer', 'Full-stack Developer'],
  },
  {
    id: '7eh4d6sabA0',
    videoId: '7eh4d6sabA0',
    title: 'Kubernetes Tutorial for Beginners [FULL COURSE in 4 Hours]',
    content:
      'This Kubernetes course covers container orchestration, essential for DevOps engineers and cloud architects.',
    link: 'https://www.youtube.com/watch?v=7eh4d6sabA0',
    prerequisiteId: null,
    skills: ['Kubernetes', 'DevOps Engineer', 'Cloud Architect'],
  },
  {
    id: 'KJgsSFOSQv0',
    videoId: 'KJgsSFOSQv0',
    title: 'C Programming Tutorial for Beginners',
    content:
      'Learn C programming from scratch. This course covers basic syntax, data structures, and algorithms.',
    link: 'https://www.youtube.com/watch?v=KJgsSFOSQv0',
    prerequisiteId: null,
    skills: ['C', 'Data Structures', 'Algorithms', 'Software Engineer'],
  },
  {
    id: 'JN3JomDJg9g',
    videoId: 'JN3JomDJg9g',
    title: 'Ethical Hacking Full Course - Learn Ethical Hacking in 10 Hours',
    content:
      'This comprehensive course covers ethical hacking techniques, crucial for cybersecurity specialists.',
    link: 'https://www.youtube.com/watch?v=JN3JomDJg9g',
    prerequisiteId: null,
    skills: ['Ethical Hacking', 'Network Security', 'Cybersecurity Specialist'],
  },
  {
    id: 'GZvSYJDk-us',
    videoId: 'GZvSYJDk-us',
    title: 'JSON Crash Course',
    content:
      'Learn about JSON (JavaScript Object Notation), a crucial skill for both front-end and back-end developers.',
    link: 'https://www.youtube.com/watch?v=GZvSYJDk-us',
    prerequisiteId: null,
    skills: ['JavaScript', 'Front-end Developer', 'Back-end Developer'],
  },
  {
    id: 'Oe421EPjeBE',
    videoId: 'Oe421EPjeBE',
    title: 'Node.js and Express.js - Full Course',
    content:
      'This course covers Node.js and Express.js, essential technologies for back-end development. Learn how to build RESTful APIs and handle server-side logic.',
    link: 'https://www.youtube.com/watch?v=Oe421EPjeBE',
    prerequisiteId: null,
    skills: ['Node.js', 'RESTful APIs', 'Back-end Developer'],
  },
  {
    id: 'GhQdlIFylQ8',
    videoId: 'GhQdlIFylQ8',
    title: 'Intro to Machine Learning (ML Zero to Hero - Part 1)',
    content:
      'This course introduces the basics of machine learning, covering key concepts and techniques.',
    link: 'https://www.youtube.com/watch?v=GhQdlIFylQ8',
    prerequisiteId: null,
    skills: ['Machine Learning', 'Data Scientist', 'Machine Learning Engineer'],
  },
  {
    id: 'gCNMNJAFReM',
    videoId: 'gCNMNJAFReM',
    title: 'Vue JS Crash Course',
    content:
      'Learn Vue.js, a popular JavaScript framework for building user interfaces, in this crash course.',
    link: 'https://www.youtube.com/watch?v=gCNMNJAFReM',
    prerequisiteId: null,
    skills: ['Vue.js', 'JavaScript', 'Front-end Developer'],
  },
  {
    id: 'pKd0Rpw7O48',
    videoId: 'pKd0Rpw7O48',
    title: 'How to Create a REST API With Express JS in 3 Minutes',
    content:
      'This quick tutorial shows how to create a RESTful API using Express.js, a crucial skill for back-end developers.',
    link: 'https://www.youtube.com/watch?v=pKd0Rpw7O48',
    prerequisiteId: null,
    skills: ['RESTful APIs', 'Node.js', 'Back-end Developer'],
  },
  {
    id: 'rHux0gMZ3Eg',
    videoId: 'rHux0gMZ3Eg',
    title: 'Ruby Programming Language - Full Course',
    content:
      'Learn Ruby programming from scratch in this comprehensive course, ideal for back-end and full-stack developers.',
    link: 'https://www.youtube.com/watch?v=rHux0gMZ3Eg',
    prerequisiteId: null,
    skills: ['Ruby', 'Back-end Developer', 'Full-stack Developer'],
  },
  {
    id: 'RBSGKlAvoiM',
    videoId: 'RBSGKlAvoiM',
    title:
      'Data Structures Easy to Advanced Course - Full Tutorial from a Google Engineer',
    content:
      'This in-depth course covers various data structures, essential knowledge for software engineers and computer scientists.',
    link: 'https://www.youtube.com/watch?v=RBSGKlAvoiM',
    prerequisiteId: null,
    skills: ['Data Structures', 'Algorithms', 'Software Engineer'],
  },
  {
    id: 'Ke90Tje7VS0',
    videoId: 'Ke90Tje7VS0',
    title: 'React JS - React Tutorial for Beginners',
    content:
      'Learn React.js from scratch in this beginner-friendly tutorial, covering components, state, and props.',
    link: 'https://www.youtube.com/watch?v=Ke90Tje7VS0',
    prerequisiteId: null,
    skills: ['React', 'JavaScript', 'Front-end Developer'],
  },
  {
    id: 'vLnPwxZdW4Y',
    videoId: 'vLnPwxZdW4Y',
    title: 'C# Tutorial - Full Course for Beginners',
    content:
      'This comprehensive C# course covers everything from basic syntax to advanced concepts, ideal for back-end developers.',
    link: 'https://www.youtube.com/watch?v=vLnPwxZdW4Y',
    prerequisiteId: null,
    skills: ['C#', 'Back-end Developer', 'Software Engineer'],
  },
  {
    id: 'WXsD0ZgxjRw',
    videoId: 'WXsD0ZgxjRw',
    title: 'Complete MongoDB Tutorial #1 - What is MongoDB?',
    content:
      'Learn about MongoDB, a popular NoSQL database, in this comprehensive tutorial series.',
    link: 'https://www.youtube.com/watch?v=WXsD0ZgxjRw',
    prerequisiteId: null,
    skills: ['NoSQL', 'MongoDB', 'Back-end Developer'],
  },
  {
    id: 'kqtD5dpn9C8',
    videoId: 'kqtD5dpn9C8',
    title: 'Python for Beginners - Learn Python in 1 Hour',
    content:
      'This quick Python tutorial covers the basics of the language, perfect for beginners in software engineering or data science.',
    link: 'https://www.youtube.com/watch?v=kqtD5dpn9C8',
    prerequisiteId: null,
    skills: ['Python', 'Software Engineer', 'Data Scientist'],
  },
  {
    id: 'YS4e4q9oBaU',
    videoId: 'YS4e4q9oBaU',
    title: 'Ethical Hacking Course for Beginners',
    content:
      'Learn the basics of ethical hacking and penetration testing in this beginner-friendly course.',
    link: 'https://www.youtube.com/watch?v=YS4e4q9oBaU',
    prerequisiteId: null,
    skills: [
      'Ethical Hacking',
      'Penetration Testing',
      'Cybersecurity Specialist',
    ],
  },
]

import React, { useEffect, useState } from 'react'
import { ReactFlowProvider } from 'react-flow-renderer'
import CoursePath from './component/CourseGraph/CoursePath' // 引入CoursePath组件
import { UVACourseData, VideoData } from './data/UVAData'
import Sidebar from './component/SideBar/Sidebar'
import { getSkillOf } from './data/SkillSet'
import { calculatePath, calculatePathWithVideos } from './utils/PathCalculator'
import './css/global.css'
import LoginWindow from './component/auth/LoginWindow' // Import LoginWindow
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebase/firebase'
import { savePathForUser } from './api/pathService'
import { signOutFromFirebase } from './api/authService' // Import signOut function

const allData = [...UVACourseData, ...VideoData]

const App = () => {
  const [selectedRole, setSelectedRole] = useState('UVA CS Path')
  const [skills, setSkills] = useState([])
  const [requiredCourses, setRequiredCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthChecked, setIsAuthChecked] = useState(false)
  const [loadFromPath, setLoadFromPath] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user)
      setIsAuthChecked(true)
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (loadFromPath) {
      return
    }
    if (selectedRole === 'UVA CS Path') {
      setRequiredCourses(UVACourseData)
    } else if (skills.length > 0) {
      setLoading(true)
      const courses = calculatePath(skills, allData)
      setRequiredCourses(courses)

      calculatePathWithVideos(skills, allData).then((courses) => {
        setRequiredCourses(courses)
        setLoading(false)
      })
    } else {
      setRequiredCourses([])
    }
  }, [selectedRole, skills, loadFromPath])

  const handleRoleChange = (role) => {
    setSelectedRole(role)
    setSkills(getSkillOf(role) || [])
  }

  const handleDeleteSkill = (skillToDelete) => {
    setSkills(skills.filter(skill => skill !== skillToDelete))
  }

  const handleAddSkill = (newSkill) => {
    if (!skills.includes(newSkill)) {
      setSkills([...skills, newSkill])
    }
  }

  const handleSavePath = () => {
    if (!isAuthenticated) {
      alert("Please log in to save your path.")
      return
    }
    if (auth.currentUser.isAnonymous) {
      setIsAuthenticated(false)
      return
    }
    const path = {
      name: "Untitled",
      role: selectedRole,
      userId: auth.currentUser.uid,
      courses: requiredCourses,
      skills: skills,
    }
    savePathForUser(path)
  }

  const handleSignOut = async () => {
    try {
      await signOutFromFirebase()
      setIsAuthenticated(false)
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      {loading && <div className="loading-indicator">Loading...</div>}
      {!isAuthChecked ? (
        <div className="loading-indicator">Checking authentication...</div>
      ) : (
        !isAuthenticated && <LoginWindow onClose={() => setIsAuthenticated(true)} guestLogin={!isAuthenticated} />
      )}

      <Sidebar
        selectedRole={selectedRole}
        onRoleChange={handleRoleChange}
        skills={skills}
        onDeleteSkill={handleDeleteSkill}
        onAddSkill={handleAddSkill}
        setRequiredCourses={setRequiredCourses}
        setSkills={setSkills}
        setLoadFromPath={setLoadFromPath}
        setSelectedRole={setSelectedRole}
      />
      <div style={{ flex: 1 }}>
        <h1>Course Planner</h1>
        <div className="button-container">
          <button className="save-button" onClick={handleSavePath}>Save</button>
          {isAuthenticated && <button className="signout-button" onClick={handleSignOut}>Sign Out</button>}
        </div>
        <ReactFlowProvider>
          <CoursePath courses={requiredCourses} />
        </ReactFlowProvider>
      </div>
    </div>
  )
}

export default App

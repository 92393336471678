import React, { useState } from 'react'
import { Handle } from 'react-flow-renderer'
import VideoCard from './VideoCard'
import '../../css/component/CourseGraph/node.css'

const VideoNode = ({ data }) => {
  const { title, videoId, content, skills } = data
  const [showPopup, setShowPopup] = useState(false)

  const handleLearnMoreClick = (e) => {
    e.preventDefault()
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
  }

  return (
    <div className="node video-node">
      <Handle type="target" position="left" />
      <div className="video-thumbnail">
        <img
          src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
          alt={title}
          className="thumbnail-img"
        />
      </div>
      <h3 className="node-title">{title}</h3>
      <button className="course-node-button" onClick={handleLearnMoreClick}>
        Learn More
      </button>
      <Handle type="source" position="right" />

      {showPopup && (
        <VideoCard
          title={title}
          videoId={videoId}
          content={content}
          skills={skills}
          onClose={handleClosePopup}
        />
      )}
    </div>
  )
}

export default VideoNode

import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyD90wQciwwwpv4Yu14O-8WLUkBrANh1iuc",
  authDomain: "courseplanner-54dbe.firebaseapp.com",
  projectId: "courseplanner-54dbe",
  storageBucket: "courseplanner-54dbe.appspot.com",
  messagingSenderId: "584745563072",
  appId: "1:584745563072:web:af84316056dc56d4ca0e25"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const googleProvider = new GoogleAuthProvider()

export { auth, googleProvider }
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase/firebase'
import { getSavedPathsByUserId } from '../../api/pathService'

const SavedPaths = ({
  setLoadFromPath,
  setRequiredCourses,
  setSkills,
  setSelectedRole,
}) => {
  const [savedPaths, setSavedPaths] = useState([])

  useEffect(() => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid
      getSavedPathsByUserId(userId).then((paths) => {
        setSavedPaths(paths)
      })
    }
  }, [auth.currentUser])

  const handleLoadPath = (path) => {
    setLoadFromPath(true)
    setRequiredCourses(path.courses)
    setSkills(path.skills)
    setSelectedRole(path.role)
    setTimeout(() => setLoadFromPath(false), 0) // Avoid re-rendering
  }

  const handleEditPath = (e, path) => {
    e.stopPropagation()
    // Logic to edit the path name
  }

  return (
    <div className="saved-paths-list" style={{ marginTop: '20px' }}>
      <h3>Saved Plans</h3>
      <ul>
        {savedPaths.map((path) => (
          <li key={path.id} onClick={() => handleLoadPath(path)}>
            {path.name}
            <span
              className="edit-icon"
              onClick={(e) => handleEditPath(e, path)}>
              ✏️
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SavedPaths

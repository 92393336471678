import { getVideoBySkill } from "../api/videoService"

const calculatePath = (skills, courses) => {
  const requiredCourses = new Set()
  const courseMap = new Map()

  // Create a map of courses by their ID for easy lookup
  courses.forEach((course) => courseMap.set(course.id, course))

  // Helper function to add a course and its prerequisites
  const addCourseAndPrerequisites = (courseId) => {
    if (!courseId || requiredCourses.has(courseId)) return

    const course = courseMap.get(courseId)
    if (!course) return

    requiredCourses.add(courseId)

    // Add prerequisites recursively
    if (course.prerequisiteId) {
      addCourseAndPrerequisites(course.prerequisiteId)
    }
  }

  // Find courses that teach the required skills
  courses.forEach((course) => {
    if (course.skills.some((skill) => skills.includes(skill))) {
      addCourseAndPrerequisites(course.id)
    }
  })

  // Convert the set to an array and sort by prerequisite order
  const sortedCourses = Array.from(requiredCourses).sort((a, b) => {
    const courseA = courseMap.get(a)
    const courseB = courseMap.get(b)
    if (courseA.prerequisiteId === courseB.id) return 1
    if (courseB.prerequisiteId === courseA.id) return -1
    return 0
  })

  // Return the sorted list of course objects
  return sortedCourses.map((id) => courseMap.get(id))
}

const findUnsatisfiedSkills = (skills, selectedCourses) => {
  const satisfiedSkills = new Set()
  selectedCourses.forEach((course) => {
    course.skills.forEach((skill) => satisfiedSkills.add(skill))
  })
  const unsatisfiedSkills = skills.filter((skill) => !satisfiedSkills.has(skill))
  return unsatisfiedSkills
}

const findVideosForSkills = async (skills) => {
  const videoPromises = skills.map((skill) =>
    getVideoBySkill(skill).catch((error) => {
      console.error(`Error fetching video for skill ${skill}:`, error)
      return null // Return null or handle the error as needed
    })
  )

  const videos = await Promise.all(videoPromises)
  return videos.filter((video) => video !== null)
}

const calculatePathWithVideos = async (skills, courses) => {
  const requiredCourses = calculatePath(skills, courses)
  const unsatisfiedSkills = findUnsatisfiedSkills(skills, requiredCourses)
  const videos = await findVideosForSkills(unsatisfiedSkills)
  return [...requiredCourses, ...videos]
}

export { calculatePath, findUnsatisfiedSkills, findVideosForSkills, calculatePathWithVideos }

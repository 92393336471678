import { UVACourseData, VideoData } from './UVAData'

const skillSet = {
  'Software Engineer': [
    'Java',
    'Python',
    'C++',
    'JavaScript',
    'Git',
    'Data Structures',
    'Algorithms',
    'Object-Oriented Programming',
    'Agile Methodologies',
    'CI/CD',
  ],
  'Front-end Developer': [
    'HTML',
    'CSS',
    'JavaScript',
    'React',
    'Vue.js',
    'Angular',
    'TypeScript',
    'Webpack',
    'Responsive Design',
    'Web Accessibility',
  ],
  'Back-end Developer': [
    'Node.js',
    'Python',
    'Ruby',
    'Java',
    'C#',
    'PHP',
    'SQL',
    'NoSQL',
    'RESTful APIs',
    'Microservices',
  ],
  'Full-stack Developer': [
    'JavaScript',
    'Python',
    'Ruby',
    'Java',
    'HTML',
    'CSS',
    'React',
    'Node.js',
    'MongoDB',
    'SQL',
    'Git',
    'Docker',
  ],
  'Data Scientist': [
    'Python',
    'R',
    'SQL',
    'Machine Learning',
    'Deep Learning',
    'Data Visualization',
    'Statistical Analysis',
    'Big Data Technologies',
    'Pandas',
    'NumPy',
    'SciPy',
  ],
  'DevOps Engineer': [
    'Linux',
    'Shell Scripting',
    'Git',
    'Docker',
    'Kubernetes',
    'Jenkins',
    'AWS/Azure/GCP',
    'Ansible',
    'Terraform',
    'Monitoring Tools',
  ],
  'Mobile App Developer': [
    'Java',
    'Kotlin',
    'Swift',
    'Objective-C',
    'React Native',
    'Flutter',
    'iOS SDK',
    'Android SDK',
    'Mobile UI/UX Design',
    'RESTful APIs',
  ],
  'Cloud Architect': [
    'AWS',
    'Azure',
    'Google Cloud',
    'Kubernetes',
    'Docker',
    'Serverless',
    'Microservices',
    'Cloud Security',
    'Scalability',
    'Disaster Recovery',
  ],
  'Cybersecurity Specialist': [
    'Network Security',
    'Cryptography',
    'Ethical Hacking',
    'Penetration Testing',
    'Security Information and Event Management (SIEM)',
    'Firewall Management',
    'Intrusion Detection Systems',
    'Risk Assessment',
    'Compliance',
  ],
  'Machine Learning Engineer': [
    'Python',
    'TensorFlow',
    'PyTorch',
    'Scikit-learn',
    'Deep Learning',
    'Natural Language Processing',
    'Computer Vision',
    'Data Mining',
    'Feature Engineering',
    'Model Deployment',
  ],
}

export function getSkillOf(role) {
  return skillSet[role]
}

export const ROLE = {
  SOFTWARE_ENGINEER: 'Software Engineer',
  FRONT_END_DEVELOPER: 'Front-end Developer',
  BACK_END_DEVELOPER: 'Back-end Developer',
  FULL_STACK_DEVELOPER: 'Full-stack Developer',
  DATA_SCIENTIST: 'Data Scientist',
  DEV_OPS_ENGINEER: 'DevOps Engineer',
  MOBILE_APP_DEVELOPER: 'Mobile App Developer',
  CLOUD_ARCHITECT: 'Cloud Architect',
}

export const SKILLS = Array.from(
  new Set([...UVACourseData, ...VideoData].flatMap((item) => item.skills))
)

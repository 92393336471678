import React from 'react'

const SkillTag = ({ skill, onDelete }) => {
  return (
    <span
      style={{
        backgroundColor: '#e0e0e0',
        padding: '5px 10px',
        borderRadius: '15px',
        fontSize: '14px',
        display: 'inline-flex',
        alignItems: 'center',
        margin: '2px',
      }}>
      {skill}
      <button
        onClick={() => onDelete(skill)}
        style={{
          marginLeft: '5px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '14px',
        }}>
        ×
      </button>
    </span>
  )
}

export default SkillTag

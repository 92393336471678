import React from 'react'
import ReactDOM from 'react-dom'
import '../../css/component/CourseGraph/VideoCard.css'

const VideoCard = ({ title, videoId, onClose, skills, content }) => {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'popup-overlay') {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <h2>{title}</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <p>{content}</p>
        <div className="skills-container">
          <h3>Skills:</h3>
          <ul>
            {skills.map((skill, index) => (
              <li key={index} className="skill-tag">
                {skill}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default VideoCard

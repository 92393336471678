import React, { useState } from 'react'
import { SKILLS } from '../../data/SkillSet'
import SkillTag from './SkillTag'

const SkillManager = ({ skills, onAddSkill, onDeleteSkill }) => {
  const [inputSkill, setInputSkill] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const handleSkillInputChange = (e) => {
    const value = e.target.value
    setInputSkill(value)

    if (value.trim() !== '') {
      const filteredSuggestions = SKILLS.filter((skill) =>
        skill.toLowerCase().includes(value.toLowerCase())
      )
      setSuggestions(filteredSuggestions)
    } else {
      setSuggestions([])
    }
  }

  const handleAddSkill = (skill) => {
    if (skill && !skills.includes(skill)) {
      onAddSkill(skill)
      setInputSkill('')
      setSuggestions([])
    }
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <h3>Skills</h3>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          marginTop: '10px',
        }}>
        {skills.map((skill) => (
          <SkillTag key={skill} skill={skill} onDelete={onDeleteSkill} />
        ))}
      </div>
      <div className="skill-input-container">
        <input
          type="text"
          value={inputSkill}
          onChange={handleSkillInputChange}
          placeholder="Add a skill"
          className="skill-input"
        />
        {suggestions.length > 0 && (
          <ul className="skill-suggestions">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion}
                onClick={() => handleAddSkill(suggestion)}
                className="skill-suggestion-item">
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default SkillManager

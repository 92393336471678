import React, { useState } from 'react'
import {
  signInWithEmail,
  signInWithGoogle,
  signInAnonymouslyInFirebase,
} from '../../api/authService'
import '../../css/component/auth/LoginWindow.scss'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const LoginWindow = ({ onClose, guestLogin = true }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleEmailLogin = async () => {
    try {
      await signInWithEmail(email, password)
      onClose()
    } catch (error) {
      console.error('Error logging in with email:', error)
    }
  }

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle()
      onClose()
    } catch (error) {
      console.error('Error logging in with Google:', error)
    }
  }

  const handleAnonymousLogin = async () => {
    const auth = getAuth()
    onAuthStateChanged(auth, async (user) => {
      if (user && user.isAnonymous) {
        console.log('Already logged in anonymously')
        onClose()
      } else {
        try {
          await signInAnonymouslyInFirebase()
          onClose()
        } catch (error) {
          console.error('Error logging in anonymously:', error)
        }
      }
    })
  }

  return (
    <div className="login-window">
      <div className="login-window-content">
        <h2>Login</h2>
        {/* <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleEmailLogin}>Login with Email</button> */}
        <button onClick={handleGoogleLogin}>Login with Google</button>
        {guestLogin && (
          <button onClick={handleAnonymousLogin}>Continue as Guest</button>
        )}
        {!guestLogin && <button onClick={onClose}>Continue as Guest</button>}
      </div>
    </div>
  )
}

export default LoginWindow

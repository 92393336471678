import React from 'react'
import RoleSelector from './RoleSelector'
import SkillManager from './SkillManager'
import SavedPaths from './SavedPaths'
import Assistant from './Assistant' // 新增导入
import '../../css/component/SideBar/Sidebar.scss'
import Chatbot from './Chatbot'

const Sidebar = ({
  selectedRole,
  onRoleChange,
  skills,
  onDeleteSkill,
  onAddSkill,
  setRequiredCourses,
  setSkills,
  setLoadFromPath,
  setSelectedRole,
  onGenerate, // 新增属性
}) => {
  return (
    <div className="sidebar" style={{ width: '250px', padding: '20px' }}>
      <RoleSelector selectedRole={selectedRole} onRoleChange={onRoleChange} />
      <SkillManager
        skills={skills}
        onAddSkill={onAddSkill}
        onDeleteSkill={onDeleteSkill}
      />
      <SavedPaths
        setLoadFromPath={setLoadFromPath}
        setRequiredCourses={setRequiredCourses}
        setSkills={setSkills}
        setSelectedRole={setSelectedRole}
      />
    </div>
  )
}

export default Sidebar
